import React from 'react'
import { render } from 'react-dom'
import InstantSearch from './instant-search'
import { typesenseSearchClient } from '../../utils/typesenseSearchClient'

export default function initSearchResultsPage(config) {
  const { selectors, ...searchPageConfig } = config
  const rootEl = document.querySelector(selectors?.searchResultsPage || "#search-results")

  rootEl.innerHTML = ""

  return render(
    <React.StrictMode>
      <TypesenseSearchResultsPage config={searchPageConfig} />
    </React.StrictMode>,
    rootEl
  )
}

export function TypesenseSearchResultsPage({ config }) {
  const { defaultImage, collectionNames, searchParams, typesenseClientConfig } = config
  const { searchClient } = typesenseSearchClient({
    server: {
      key: typesenseClientConfig.apiKey,
      host: typesenseClientConfig.host,
      port: typesenseClientConfig.port || 443,
    },
    options: {
      max_candidates: 250,
      group_by: 'parentSku',
      group_limit: 1,
      exhaustive_search: true,
      text_match_type: 'max_weight',
      ...searchParams,
    },
  })

  return (
    <InstantSearch
      searchClient={searchClient}
      defaultImage={defaultImage}
      searchResultsIndex={collectionNames.variants}
    />
  )
}
