import React from 'react'
import { render } from 'react-dom'
import Autocomplete from './autocomplete'
import useTypesenseClient from '../hooks/useTypesenseClient'

export default function initTypesenseSearchBar(config) {
  const { selector, ...searchbarConfig } = config
  const rootEl = document.querySelector(selector || "#quickSearch")

  rootEl.innerHTML = ""

  return render(
    <React.StrictMode>
      <TypesenseSearchbar config={searchbarConfig} />
    </React.StrictMode>,
    rootEl
  )
}

export function TypesenseSearchbar({ config }) {
  const { defaultImage, searchParams, collectionNames, typesenseClientConfig } = config
  const typesenseClient = useTypesenseClient(typesenseClientConfig)

  return (
    <Autocomplete
      typesense={typesenseClient}
      placeholder="What are you looking for?"
      defaultImage={defaultImage}
      searchParams={searchParams}
      collectionNames={collectionNames}
    />
  )
}
