export default function getDeepestCategoryPaths(productHits, categoryHits) {
  if (!productHits.length && !categoryHits.length) return []

  const outputMap = new Map()
  const urlMap = new Map()
  const deepestPaths = []

  for (const hit of categoryHits) {
    const category = hit.document.hierarchy.join(' > ')    
    urlMap.set(category, hit.document.categoryUrl)

    if (deepestPaths.every(c => !c.startsWith(category))) {
      deepestPaths.push(category)
    }
  }

  for (const hit of productHits) {
    for (const [key, category] of Object.entries(hit.categoryURL)) {
      urlMap.set(key, category.url)
    }

    for (let i = 3; i > 0; i--) {
      const currentLvl = hit[`categories.lvl${i}`]

      if (!currentLvl?.length) continue

      for (const category of hit[`categories.lvl${i}`]) {
        if (deepestPaths.every(c => !c.startsWith(category))) {
          deepestPaths.push(category)
        }
      }
    }
  }

  for (const path of deepestPaths) {
    const splitPath = path.split(' > ')
    const subArrays = []

    for (let i = 1; i <= splitPath.length; i++) {
      const joined = splitPath.slice(0, i).join(' > ')

      subArrays.push({
        name: splitPath[i - 1],
        path: joined,
        url: urlMap.get(joined),
      })
    }

    outputMap.set(path, { name: path, paths: subArrays })
  }

  return [...outputMap.values()]
}
