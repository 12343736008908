import React from 'react';

export function ClearIcon(props) {
  return (
    // <svg
    //   width="20"
    //   height="20"
    //   viewBox="0 0 20 20"
    //   fill="currentColor"
    //   {...props}
    // >
    //   <path
    //     d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //   />
    // </svg>
    <svg
      viewBox="0 0 24 24"
      width="28"
      height="28"
      stroke="currentColor"
      strokeWidth="1.5"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <line x1="18" y1="6" x2="6" y2="18"></line>
      <line x1="6" y1="6" x2="18" y2="18"></line>
    </svg>
  );
}
