import ACPrice from './ac-price';

const createCustomHit = (defaultImage) => ({ hit }) => {
  return (
    <article className="card">
      <figure className="card-figure">
        <a className="card-figure__link" href={hit.productUrl || defaultImage}>
          <div className="card-img-container">
            <img className="card-image lazyautosizes lazyloaded" src={hit.image?.url || defaultImage} alt={hit.image?.alt} title={hit.image?.alt} sizes="240px"></img>
          </div>
        </a>
        <figcaption className="card-figcaption">
          <div className="card-figcaption-body">
            <div className="card-figcaption--action-buttons">
              <a href={hit.productUrl} className="button button--small card-figcaption-button">View Options</a>
              <a className="button button--secondary button--small card-figcaption-button quickview" data-product-id={hit.bcProductId}>Quick view</a>
            </div>
          </div>
        </figcaption>
      </figure>
      <div className="card-body">
        <div>
          <h4 className="card-title">
            <a aria-label={hit.parentName} href={hit.productUrl}>
              {hit.parentName} {/*hit.options?.map(o => o.value).join(', ')*/}
            </a>
          </h4>
          <div className="card-text">
            <span className="rating--small">
              <div className="star_container"></div>
            </span>
          </div>
          <div className="card-text price">
            <div className="price-section price-section--withoutTax">
              <span className="price-section price-section--withoutTax non-sale-price--withoutTax" style={{display: 'none'}}>
                <span className="price price--non-sale"></span>
              </span>
              <span className="price price--withoutTax">
                {/* {
                  hit.priceRange ? `${formatToUsd(hit.priceRange.min)} - ${formatToUsd(hit.priceRange.max)}` : formatToUsd(hit.price ?? 0)
                } */}
                <ACPrice sku={hit.parentSku} />
              </span>
              <div className="price-section price-section--withoutTax rrp-price--withoutTax" style={{display: 'none'}}>
                <span className="price price--rrp"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
};

export default createCustomHit
