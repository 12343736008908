import React from 'react'
import getDeepestCategoryPaths from '../utils/get-deepest-category-paths';

export function CategoriesResults({ productParents, categories, autocomplete }) {
  if (!productParents || !categories) return null
  if (productParents.length + categories.items.length < 1) return null

  const combinedCategories = getDeepestCategoryPaths(productParents, categories.items)

  return (
    <section className="aa-Source">
      <div className="aa-SourceHeader">
        Categories
      </div>
      {combinedCategories.length > 0 && (
        <ul className="aa-List" {...autocomplete.getListProps()}>
          {combinedCategories.map(category => {
            return (
              <li
                key={category.name}
                className="aa-Item"
                // onMouseOver={() => loadVariants(item.document)}
              >
                <div className="aa-ItemWrapper">
                  <div className="aa-ItemContent">
                    <div className="aa-ItemContentBody">
                      <div className="aa-ItemContentTitle">
                        {
                          category.paths.map((path, i, paths) => <React.Fragment key={path.path}>
                            <a href={path.url}>{path.name}</a>
                            {(i < paths.length - 1) && <span> › </span>}
                          </React.Fragment>)
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      )}
    </section>
  );
}

export default CategoriesResults
