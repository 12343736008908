import { useMemo } from "react";
import Typesense from 'typesense'

export default function useTypesenseClient(config) {
  const client = useMemo(() => {
    return new Typesense.Client({
      nodes: [
        { host: config.host, port: config.port || 443, protocol: 'https' },
      ],
      apiKey: config.apiKey,
      connectionTimeoutSeconds: 2,
    })
  }, [config])

  return client
}
