export default function createBCGQLClient(apiToken) {
  return async function(query, options = {}) {
    const { returnCleaned = true } = options;

    const body = JSON.stringify({ query });
    const headers = new Headers();

    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${apiToken}`);

    const requestOptions = {
      method: 'POST',
      credentials: 'same-origin',
      headers,
      body,
    };

    const response = await fetch('/graphql', requestOptions);
    const serialized = await response.json();

    if (returnCleaned) {
      return cleanGqlResponse(serialized);
    }

    return serialized;
  }
}

function cleanGqlResponse(input) {
  if (!input) return null;

  if (Array.isArray(input)) {
    return input.map(e => cleanGqlResponse(e));
  }

  return Object.keys(input).reduce((result, key) => {
    /* eslint-disable no-param-reassign */
    if (input[key]?.edges) {
      result[key] = input[key].edges.map(edge => cleanGqlResponse(edge.node));
    } else if (isObject(input[key])) {
      result[key] = cleanGqlResponse(input[key]);
    } else if (key !== '__typename') {
      result[key] = input[key];
    }

    return result;
  }, {});
}

function isObject(obj) {
  return obj !== null &&
    typeof obj === 'object' &&
    !Array.isArray(obj);
}
