import initSearchResultsPage from "./search-page";
import initSearchBar from "./search-bar";
import createBCGQLClient from "./utils/gql-fetch";
import { unmountComponentAtNode } from "react-dom";
import 'instantsearch.css/themes/satellite.css'
import './search-page/search-page.scss'
import './search-bar/search-bar.scss'

async function initTypesense(shouldLoadSearchResultsPage = false, gqlToken, defaultImage) {
  if (checkIfDisabledCustomSearch()) return

  const gql = createBCGQLClient(gqlToken)

  mount()
  mount(true)

  async function mount(checkForUpdatedConfig = false) {
    if (checkForUpdatedConfig) {
      const hasChanged = await refreshSearchConfig()
      if (!hasChanged) return
    }

    const searchConfig = getSavedConfig()

    if (!searchConfig) {
      if (checkForUpdatedConfig) {
        throw new Error('Fetched search config is not valid')
      } else {
        return
      }
    }

    // Unmount before remount
    const searchBarRoot = document.querySelector(searchConfig.searchBarConfig.selector)
    const searchPageRoot = document.querySelector(searchConfig.searchPageConfig.selector)
    if (searchBarRoot) unmountComponentAtNode(searchBarRoot)
    if (searchPageRoot) unmountComponentAtNode(searchPageRoot)

    initSearchBar({
      defaultImage,
      typesenseClientConfig: searchConfig.typesenseClientConfig,
      ...searchConfig.searchBarConfig
    })

    if (shouldLoadSearchResultsPage) {
      initSearchResultsPage({
        defaultImage,
        typesenseClientConfig: searchConfig.typesenseClientConfig,
        ...searchConfig.searchPageConfig
      })
    }
  }

  async function refreshSearchConfig() {
    const fetched = await gql(`
      query metafields {
        channel {
          metafields(namespace: "Four13") {
            edges {
              node {
                value
                key
              }
            }
          }
        }
      }
    `)
  
    const configMetafield = fetched.data.channel.metafields?.find(m => m.key === 'Search Configuration')?.value
    const savedConfig = localStorage.getItem('four13_search_config')
    const hasChanged = configMetafield !== savedConfig

    if (hasChanged) {
      localStorage.setItem('four13_search_config', configMetafield)
    }

    return hasChanged
  }
}

function checkIfDisabledCustomSearch() {
  const params = new URL(location.href).searchParams

  return params.get('disable_custom_search') === 'true'
}

function getSavedConfig() {
  // const savedConfig = localStorage.getItem('four13_typesense_config')
  // let parsedConfig

  // try {
  //   parsedConfig = JSON.parse(savedConfig)
  // } catch {
  //   parsedConfig = null
  // }

  // return parsedConfig

  const config = {
    typesenseClientConfig: {
      host: 'iengdc540q3ufa7vp-1.a1.typesense.net',
      apiKey: 'vGPHLrIXYiHQ3Y9WZgrOaTcANO6HWGoP',
    },
    searchBarConfig: {
      selector: '#quickSearch',
      collectionNames: {
        pages: 'ss_dev_pages',
        blogs: 'ss_dev_blogs',
        products: 'ss_dev_products',
        variants: 'ss_dev_variants',
        categories: 'ss_dev_categories',
      },
      searchParams: {
        queryBy: [
          "sku",
          "vendorPartNumbers",
          "name",
          "parentName",
          "options.value",
          "attributes.*",
          "categories",
          "description",
          "parentSku",
        ],
        sortBy: [],
      },
      // showParentOnly: false, // Future TODO
      // mode: 'full' | 'dropdown' // not implemented yet
    },
    searchPageConfig: {
      selector: '#search-results',
      collectionNames: {
        products: 'ss_dev_products',
        variants: 'ss_dev_variants',
      },
      searchParams: {
        queryBy: [
          "sku",
          "vendorPartNumbers",
          "name",
          "parentName",
          "options.value",
          "attributes.*",
          "categories",
          "description",
          "parentSku",
        ],
      },
      showParentOnly: false,
    },
  }

  // Temporary. Metafield value structure could change anytime so we have a catch block that will set defaults.
  const infixFields = ['sku', 'vendorPartNumbers', 'parentName', 'name', 'options.value'] 
  try {
    const parsedConfig = JSON.parse(localStorage.getItem('four13_search_config'))

    config.searchBarConfig.searchParams = {
      query_by: parsedConfig.searchAttributes.filter(f => f.isSearchable).map(f => f.name).join(','),
      infix: parsedConfig.searchAttributes.filter(f => f.isSearchable)
        .map(f => infixFields.includes(f.name) ? 'always' : 'off')
        .join(','),
    }
    config.searchPageConfig.searchParams = {
      query_by: parsedConfig.searchAttributes.filter(f => f.isSearchable).map(f => f.name).join(','),
      infix: parsedConfig.searchAttributes.filter(f => f.isSearchable)
        .map(f => infixFields.includes(f.name) ? 'always' : 'off')
        .join(','),
    }
  } catch {
    config.searchBarConfig.searchParams = {
      query_by: config.searchBarConfig.searchParams.queryBy.join(','),
      infix: config.searchBarConfig.searchParams.queryBy
        .map(f => infixFields.includes(f.name) ? 'always' : 'off')
        .join(','),
    }
    config.searchPageConfig.searchParams = {
      query_by: config.searchPageConfig.searchParams.queryBy.join(','),
      infix: config.searchPageConfig.searchParams.queryBy
        .map(f => infixFields.includes(f.name) ? 'always' : 'off')
        .join(','),
    }
  }

  return config
}

window.initTypesense = initTypesense;
