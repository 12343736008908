export function PageAndBlogResults({ pagesAndBlogs }) {
  if (!pagesAndBlogs || pagesAndBlogs.items.length < 1) return null

  return (
    <section className="aa-Source">
      <div className="aa-SourceHeader">
        Pages / Blog Posts
      </div>
      <ul className="aa-List">
        {pagesAndBlogs.items.map((item) => {
          return (
            <li
              key={item.document.id}
              className="aa-Item"
            >
              <a href={item.document.productUrl}>
                <div className="aa-ItemWrapper">
                  <div className="aa-ItemContent">
                    <div className="aa-ItemContentBody">
                      <div className="aa-ItemContentTitle">
                        {item.document.name ?? item.document.title}
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </li>
          )})}
      </ul>
    </section>
  )
}
