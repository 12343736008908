import React from 'react';
import {
  InstantSearch,
  Configure,
  Hits,
  Pagination,
  RefinementList,
  HierarchicalMenu,
  Panel
} from 'react-instantsearch-dom';

import createCustomHit from './create-custom-hit'

export default function InstantSearchComponent({ searchClient, defaultImage, searchResultsIndex }) {
  const [query] = React.useState(new URL(location.href).searchParams.get('query'))
  const Hit = createCustomHit(defaultImage)

  return (
    <>
      <p className='result-text'>Showing results for "{query}"</p>
      <InstantSearch
        indexName={searchResultsIndex}
        searchClient={searchClient}
      >
        <Configure hitsPerPage={12} query={query} />
        <div className='facets'>
          <Panel header="Categories">
            <HierarchicalMenu
              attributes={[
                'categories.lvl0',
                'categories.lvl1',
                'categories.lvl2',
                'categories.lvl3',
              ]}
            />
          </Panel>
          <Panel header="Material">
            <RefinementList attribute="attributes.material" showMore />
          </Panel>
          <Panel header="Series">
            <RefinementList attribute="attributes.series" showMore />
          </Panel>
        </div>
        <Hits hitComponent={Hit} />
        <Pagination />
      </InstantSearch>
    </>
  );
}
