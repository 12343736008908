import { Highlight } from './highlight';

export function ProductResults({
  products,
  query,
  defaultImage,
  autocomplete,
  selected,
  setSelectedProduct,
}) {
  if (!products) return null;

  const { source, items } = products;
  const encodedQuery = encodeURIComponent(query);

  if (items.length < 1) return null

  return (
    <section className="aa-Source product-results">
      {items.length > 0 && (
        <ul className="aa-List" {...autocomplete.getListProps()}>
          {items.slice(0, 3).map((item) => {
            const { parentName, parentSku, image, name, productUrl } = item.hits[0].document;
            const parentUrl = productUrl.split('?')[0]

            return (
              <li
                key={parentSku}
                className="aa-Item"
                title={parentName}
                {...autocomplete.getItemProps({ item, source })}
              >
                {/* <a href={item.document.productUrl}> */}
                  <div className="aa-ItemWrapper">
                    <div className="aa-ItemContent">
                      <div className="aa-ItemIcon aa-ItemIcon--alignTop">
                        <img
                          src={image?.url || defaultImage}
                          alt={parentName}
                          width={50}
                          height={50}
                        />
                      </div>
                      <div className="aa-ItemContentBody">
                        <div className="aa-ItemContentTitle product-title">
                          <a href={parentUrl}>
                            <Highlight hit={item.hits[0].document} attribute='parentName' />
                          </a>
                        </div>
                        <ul className='group-variants'>
                          {item.hits.slice(0, 3).map((hit) => {
                            const { sku, options, productUrl } = hit.document
                            const optionsText = options?.length ? options.map(o => o.value).join(', ') : null

                            return (
                              <li title={name} key={sku}>
                                <a href={productUrl}><span className='sku-badge'>{sku}</span> {optionsText}</a>
                              </li>
                            )
                          })}
                        </ul>
                        {
                          item.hits.length > 3
                            ? selected?.parentSku === item.hits[0].document.parentSku
                              ? <div className='see-more' onClick={(e) => {
                                  e.preventDefault()
                                  e.stopPropagation()
                                  setSelectedProduct(null)
                                }}>
                                <a>Showing more on the left...</a>
                              </div>
                              : <div className='see-more' onClick={(e) => {
                                  e.preventDefault()
                                  e.stopPropagation()
                                  setSelectedProduct(item.hits[0].document)
                                }}>
                                <a>See more...</a>
                              </div>
                            : null
                        }
                      </div>
                    </div>
                  </div>
                {/* </a> */}
              </li>
            );
          })}
        </ul>
      )}
      {
        (items.length > 3) &&
        <div className="aa-SourceFooter">
          <a className="" href={`/search/?query=${encodedQuery}`}>
            View More Results...
          </a>
        </div>
      }
    </section>
  );
}

export default ProductResults
