import React from "react"

export default function ACPrice({ sku, qty = 1 }) {
  if (!sku) return null

  const [price, setPrice] = React.useState(null)

  React.useEffect(() => {
    (async function() {
      await waitForTranzetta()
      const price = await window.getAcumaticaPrices([{ sku, qty }])

      setPrice(window.generatePriceText(price[sku]))
    })()
  }, [sku, qty])

  return price ?? 'Loading...'
}

async function waitForTranzetta() {
  let retries = 100

  while (retries > 0) {
    if (window.getAcumaticaPrices) return
    await new Promise(r => setTimeout(r, 50)) // 50ms
    retries--
  }
}
