import { ClearIcon } from "./clear-icon"

export function FullVariantList(props) {
  const {
    selected,
    variants,
    isLoading,
    autocomplete,
    setSelectedProduct,
  } = props

  if (!selected || !variants || isLoading) return null

  let halfHasNameHit = false

  for (let i = 0, withNameHit = 0; i < variants.items.length; i++) {
    if (variants.items[i].highlight.name) {
      withNameHit++
    }

    if (withNameHit >= Math.round(variants.items.length / 2)) {
      halfHasNameHit = true
      break
    }
  }

  const closeVariantsList = () => setSelectedProduct(null)

  return (
    <div className='full-variant-list-wrapper'>
      <div className='close-variant-list-container'>
        <button onClick={closeVariantsList}>
          <ClearIcon />
        </button>
      </div>
      <section className='aa-Source aa-Source--variants' {...autocomplete.getListProps()}>
        <List variants={variants} autocomplete={autocomplete} halfHasNameHit={halfHasNameHit} />
      </section>
    </div>
  )
}

function List({ variants, autocomplete, halfHasNameHit }) {
  if (variants.items.length === 0) return null

  console.log(halfHasNameHit)

  return (
    <ul className="aa-List">
      {variants.items.map((item) => {
        if (item.document.sku === item.document.parentSku) return null

        const joinedOptions = item.document.options?.map(o => o.value).join(', ')

        return (
          <li
            key={item.document.id}
            className="aa-Item"
            title={item.document.name}
            {...autocomplete.getItemProps({ item, variants })}
          >
            <a href={item.document.productUrl}>
              <div className="aa-ItemWrapper">
                <div className="aa-ItemContent">
                  <div className="aa-ItemContentBody">
                    <div className="aa-ItemContentTitle">
                      {/* <Highlight hit={item.document} attribute='name' /> */}
                      <span className={`sku-badge${
                        (item.highlight.name && !halfHasNameHit) ? ' with-name-hit' : ''
                      }`}>
                        {item.document.sku}
                      </span> {joinedOptions}
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </li>
        );
      })}
    </ul>
  )
}

export default FullVariantList
