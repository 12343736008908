import { createAutocomplete } from '@algolia/autocomplete-core';
import React from 'react';

import { ClearIcon } from './clear-icon';
import { SearchIcon } from './search-icon';
import { ProductResults } from './products-results'
import { CategoriesResults } from './categories-results';
import { PageAndBlogResults } from './page-blog-results';
import { FullVariantList } from './full-variants';

import "@algolia/autocomplete-theme-classic/dist/theme.css";
import "./search-bar.scss"

/**
 * Modified Autocomplete from this example:
 * https://github.com/algolia/autocomplete/blob/e548901afa92871b5b92ef5339ef4c9bf85ec16e/examples/react-renderer/src/Autocomplete.tsx
 */
export default function Autocomplete(props) {
  const { typesense, defaultImage, searchParams, collectionNames, ...otherProps } = props
  const [autocompleteState, setAutocompleteState] = React.useState({
    collections: [],
    completion: null,
    context: {
      selectedProduct: null,
    },
    isOpen: false,
    query: '',
    activeItemId: null,
    status: 'idle',
  });

  const autocomplete = React.useMemo(
    () =>
      createAutocomplete({
        onStateChange({ state }) {
          setAutocompleteState(state);
        },
        insights: true,
        onSubmit({ state }) {
          if (state.query.length) {
            // const [productCollection] = state.collections
            // const exactSkuResult = items.find(i => i?.variants?.some(v => v.childSku?.toUpperCase() === state.query.toUpperCase()))
            // const exactSkuResultParent = productCollection.items.find(i => i?.parentSku?.toUpperCase() === state.query.toUpperCase())

            // if (exactSkuResult || exactSkuResultParent) {
            //   window.location = exactSkuResult?.productUrl ?? exactSkuResultParent?.productUrl
            // } else {
              window.location = `/search/?query=${encodeURIComponent(state.query)}`
            // }
          }
        },
        getSources() {
          return [
            {
              sourceId: 'products',
              async getItems({ query }) {
                const productResults = await typesense.collections(collectionNames.variants).documents().search({
                  q: query,
                  exhaustive_search: true,
                  text_match_type: 'max_weight',
                  // per_page: 250,
                  highlight_full_fields: 'name,sku',
                  group_by: 'parentSku',
                  group_limit: 4,
                  max_candidates: 250,
                  ...searchParams,
                })

                return productResults.grouped_hits
              },
            },
            {
              sourceId: 'pagesAndBlogs',
              async getItems({ query }) {
                // TODO: maybe exclude this in the pull job?
                const excludedPages = [
                  'Quote',
                  'Buy Again',
                  'Quotes List',
                  'Address Book',
                  'Quick Order Pad',
                ]

                const [pageResults, blogResults] = await Promise.all([
                  typesense.collections(collectionNames.pages).documents().search({
                    q: query,
                    per_page: 5,
                    query_by: 'name',
                    highlight_full_fields: 'name',
                  }),
                  typesense.collections(collectionNames.blogs).documents().search({
                    q: query,
                    per_page: 5,
                    query_by: 'title,metaDescription,body',
                    highlight_full_fields: 'title'
                  }),
                ])

                return [
                  ...pageResults.hits.filter(hit => !excludedPages.includes(hit.document.title)),
                  ...blogResults.hits,
                ]
              },
            },
            {
              sourceId: 'categories',
              async getItems({ query }) {
                const categoryResults = await typesense.collections(collectionNames.categories).documents().search({
                  q: query,
                  per_page: 5,
                  query_by: 'name',
                  highlight_full_fields: 'name'
                })

                return categoryResults.hits
              },
            },
            {
              sourceId: 'variants',
              async getItems({ query, state }) {
                if (!state.context.selectedProduct) return [];

                const variantsResults = await typesense.collections(collectionNames.variants).documents().search({
                  q: query,
                  exhaustive_search: true,
                  text_match_type: 'max_weight',
                  per_page: 250,
                  filter_by: `parentSku:=${state.context.selectedProduct.parentSku}`,
                  ...searchParams,
                })

                return variantsResults.hits
              },
            },
          ];
        },

        ...otherProps,
      }),
    [props]
  );

  const setSelectedProduct = (product) => {
    if (!autocomplete) return

    autocomplete.setContext({
      selectedProduct: product,
    })

    if (product) autocomplete.refresh()
  }

  const inputRef = React.useRef(null);
  const formRef = React.useRef(null);
  const panelRef = React.useRef(null);

  const { getEnvironmentProps } = autocomplete;

  React.useEffect(() => {
    if (!formRef.current || !panelRef.current || !inputRef.current) {
      return undefined;
    }

    const { onTouchStart, onTouchMove, onMouseDown } = getEnvironmentProps({
      formElement: formRef.current,
      inputElement: inputRef.current,
      panelElement: panelRef.current,
    });

    window.addEventListener('touchstart', onTouchStart);
    window.addEventListener('touchmove', onTouchMove);
    window.addEventListener('mousedown', onMouseDown);

    return () => {
      window.removeEventListener('touchstart', onTouchStart);
      window.removeEventListener('touchmove', onTouchMove);
      window.removeEventListener('mousedown', onMouseDown);
    };
  }, [getEnvironmentProps, autocompleteState.isOpen]);

  function setPanelHeight() {
    const input = inputRef.current

    if (!input) return

    const rootStyle = document.querySelector("html").style
    const { bottom } = input.getBoundingClientRect()
    const panelHeight = window.innerHeight - bottom
  
    rootStyle.setProperty('--search-panel-height', `${panelHeight}px`)
  }

  React.useEffect(() => {
    if (autocompleteState.isOpen) {
      setPanelHeight()
    }
  }, [autocompleteState.isOpen])

  React.useEffect(() => {
    setSelectedProduct(null)
  }, [autocompleteState.query])

  const [products, pagesAndBlogs, categories, variants] = autocompleteState.collections

  return (
    <div className="aa-Autocomplete" {...autocomplete.getRootProps({})}>
      <form
        ref={formRef}
        className="aa-Form"
        {...autocomplete.getFormProps({ inputElement: inputRef.current })}
      >
        <div className="aa-InputWrapperPrefix">
          <label className="aa-Label" {...autocomplete.getLabelProps({})}>
            <button className="aa-SubmitButton" type="submit" title="Submit">
              <SearchIcon />
            </button>
          </label>
        </div>
        <div className="aa-InputWrapper">
          <input
            className="aa-Input"
            ref={inputRef}
            {...autocomplete.getInputProps({ inputElement: inputRef.current })}
          />
        </div>
        <div className="aa-InputWrapperSuffix">
          <button className="aa-ClearButton" title="Clear" type="reset">
            <ClearIcon />
          </button>
        </div>
      </form>

      {autocompleteState.isOpen && (
        <>
          <div
            ref={panelRef}
            className={[
              'aa-Panel',
              'aa-Panel--desktop',
              autocompleteState.status === 'stalled' && 'aa-Panel--stalled',
            ]
              .filter(Boolean)
              .join(' ')}
            {...autocomplete.getPanelProps({})}
          >
            <div className="aa-PanelLayout aa-Panel--scrollable">
              <ProductResults
                query={autocompleteState?.query}
                products={products}
                selected={autocompleteState?.context.selectedProduct}
                autocomplete={autocomplete}
                defaultImage={defaultImage}
                setSelectedProduct={setSelectedProduct}
              />
              <CategoriesResults
                productParents={products.items.map(item => item.hits[0].document)}
                categories={categories}
                autocomplete={autocomplete}
              />
              <PageAndBlogResults
                pagesAndBlogs={pagesAndBlogs}
                autocomplete={autocomplete}
              />
              <FullVariantList
                selected={autocompleteState.context.selectedProduct}
                setSelectedProduct={setSelectedProduct}
                variants={variants}
                isLoading={autocompleteState.status === 'loading' || autocompleteState.status === 'stalled'}
                autocomplete={autocomplete}
              />
            </div>
          </div>
        </>
      )}

    </div>
  );
}
